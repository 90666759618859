.num {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid $g-color-green;
  border-radius: 24px;
  color: $g-color-green;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
  font-size: 24px;
}

.sec-title {
  font-family: 'Rubik', sans-serif;
  margin-top: 24px;
  font-size: 24px;
  line-height: 120%;
  font-style: normal;
  font-weight: 700;
  color: $g-color-black;
}

.sec-text {
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  margin-top: 32px;
  color: $g-color-black;
}

.con-section {
  margin: 88px 0;
}

.pr {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 44px;
}

.pr-icon {
  width: 80px;
  height: 80px;
  align-items: center;
  justify-content: center;
  background: #F6F7FA;
  border-radius: 12px;
  display: flex;
}

.pr-text {
  margin-top: 24px;
  font-family: 'Rubik', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 29px;
  text-align: center;
}

.pr-media {
  margin-top: 44px;
  text-align: center;
  height: 679px;
}

