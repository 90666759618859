.carousel .slide {
  background-color: none;
  background: none;
  height: 100%;
  img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.carousel-root,
.carousel.carousel-slider,
.slider-wrapper.axis-horizontal,
.slider {
  height: 100%;
}

.carousel.carousel-slider {
  overflow: visible;
}

.carousel .control-dots {
  bottom: -64px;
}
.carousel .control-dots .dot {
  background: #25224A;
  box-shadow: none;
  width: 10px;
  height: 10px;
  opacity: 0.32;
  &:focus {
    outline: none;
  }
  &.selected {
    background: #00D463;
  }
}