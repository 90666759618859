.header-mobile {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  background-color: #fff;

  .mobile-menu-btn {
    font-weight: bold;
    color: $g-color-black;
    font-size: 14px;
    margin-left: 6px;
    display: inline-block;
  }
}

.header-mobile__header {
  height: 52px;
}

.mobile-nav {
  height: calc(100vh - 52px);

  .mnav-it {
    text-align: center;
    margin-bottom: 14px;

    &:first-child {
      margin-top: 48px;
    }

    &.active .nav-link {
      &:after {
        opacity: 1;
      }
    }

    .nav-link {
      color: $g-color-black;
      transition: all 120ms ease-in-out;
      display: inline-block;
      padding: 4px;
      position: relative;
      text-decoration: none;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 39px;

      &:after {
        content: '';
        display: block;
        margin-top: 5px;
        border-radius: 2px;
        height: 3px;
        width: 100%;
        background-color: $g-color-blue;
        opacity: 0;
      }
    }
  }
}

.header-desktop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  background-color: #fff;

  display: none;

  .nav {
    height: 100px;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .nav-it {
    margin: 0 18px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    opacity: 0.32;
    transition: all 150ms ease-in-out;

    &:hover {
      opacity: 1;
    }

    &.active {
      opacity: 1;
    }

    &.active .nav-link {
      &:after {
        opacity: 1;
      }
    }
  }

  .nav-link {
    font-weight: 700;
    font-size: 14px;
    color: $g-color-black;
    transition: all 120ms ease-in-out;
    display: inline-block;
    padding: 4px;
    position: relative;
    text-decoration: none;

    &:after {
      content: '';
      display: block;
      margin-top: 5px;
      border-radius: 2px;
      height: 3px;
      width: 100%;
      background-color: $g-color-blue;
      opacity: 0;
      transition: all 150ms ease-in-out;
    }
  }

  // &.active,
  // &:hover {
  //   opacity: 1;
  // }

  // &.active {
  //   &:after {
  //     opacity: 1;
  //   }
  // }

}