.form-group {
  margin-bottom: 32px;
}

.form-control {
  background: #FFFFFF;
  border: 2px solid #EEEDF1;
  border-radius: 12px;
  font-size: 16px;
  line-height: 22px;
  padding: 9px 14px;
  width: 100%;
  transition: all 100ms ease-in-out;
  &:focus {
    outline: none;
    border-color: #25224A;
  }
}

.form-tip {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #25224A;
  opacity: 0.64;
  margin-top: 16px;
}