button {
  background: none;
  border: none;
  outline: none;
}

.btn {
  font-weight: 700;
  font-size: 14px;
  color: $g-color-blue;
  padding: 12px 24px;
  border: 2px solid $g-color-blue;
  border-radius: 16px;
  cursor: pointer;
}

.btn-fill {
  background-color: $g-color-blue;
  color: #fff;
}

.btn-block {
  display: block;
  width: 100%;
}