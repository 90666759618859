// Refactor, move to styled components

@media (min-width: 769px) {
  .section-promo {
    padding-top: 200px;
  }
  
  .promo-hint {
    font-size: 24px;
  }
  
  .promo-title {
    font-size: 64px;
    line-height: 77px;
  }

  .promo-media {
    justify-content: flex-end;
  }

  .sec-title {
    font-size: 48px;
    line-height: 58px;
  }

  .con-section {
    margin: 164px 0;
  }

  .pr {
    margin-bottom: 0;
  }

  .promo-img {
    margin-top: 0;
  }

  .pr-media {
    margin-top: 0;
  }
  
  .promo-description {
    margin: 0 auto;
    font-size: 32px;
    line-height: 51.2px;
  }

  .only-mobile {
    display: none;
  }
}

@media (min-width: 992px) {
  .header-desktop {
    display: block;
  }
  .header-mobile {
    display: none;
  }
}