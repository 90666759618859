.text-1 {
  font-size: 18px;
  color: $g-color-black;
  font-weight: 400;
  line-height: 30px;
}

.text-2 {
  color: $g-color-black;
  font-family: 'Rubik', sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
  margin-bottom: 64px;
}

.text-blue {
  color: $g-color-blue;
}

.text-green {
  color: $g-color-green;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left   { text-align: left !important; }
    .text#{$infix}-right  { text-align: right !important; }
    .text#{$infix}-center { text-align: center !important; }
  }
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-700 {
  font-weight: 700;
}

.l-ul {
  list-style: none;
  margin: 0;
  padding: 0 0 0 64px;

  li {
    position: relative;

    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;

    margin-bottom: 16px;

    color: $g-color-black;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: "";
      background-color: #00D463;
      font-weight: bold;
      display: inline-block;
      border-radius: 8px;
      width: 8px;
      height: 8px;
      position: absolute;
      left: -18px;
      top: 12px;
    }
  }
}