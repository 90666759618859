$spacer: 4px;
$spacers: (
        0: 0,
        1: ($spacer * 1),
        2: ($spacer * 2),
        3: ($spacer * 3),
        4: ($spacer * 4),
        5: ($spacer * 5),
        6: ($spacer * 6),
        7: ($spacer * 7),
        8: ($spacer * 8),
        9: ($spacer * 9),
        10: ($spacer * 10),
        11: ($spacer * 11)
);
// @import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/utilities/spacing";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/utilities/text";