.footer {
  margin-top: 120px;
  background-color: #1D1D1D;
  padding: 32px 0;
}

.footer-link {
  display: inline-block;
  color: #F6F7FA;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  cursor: pointer;
  text-decoration: none;
}

.footer-text {
  color: #F6F7FA;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  opacity: 0.32;
}

.footer-link, .footer-text {
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
}