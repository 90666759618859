.acc {
  background-color: #F6F7FA;
  border-radius: 12px;
  margin-bottom: 24px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
}

.acc-header {
  padding: 24px 32px;
  cursor: pointer;
  user-select: none;
}

.acc-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
}

.acc-btn {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 26px;
  right: 23px;
  transition: all 150ms ease-in-out;
}

.acc-btn--opened {
  transform: rotate(-180deg);
}

.acc-content {
  padding: 0 32px 24px 32px;
}