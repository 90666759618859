.section-promo {
  padding-top: 66px;
}

.promo-hint {
  margin-top: 44px;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 35px;
}

.promo-title {
  margin: 20px 0 0 0;
  font-size: 44px;
  font-weight: 700;
  line-height: 52px;
  font-family: 'Rubik', sans-serif;
  color: $g-color-black;
}

.promo-img {
  margin-top: 88px;
}

.promo-media {
  display: flex;
  justify-content: center;
}

.promo-subtitle {
  margin-top: 24px;
  margin-bottom: 0;
}

.promo-actions {
  margin-top: 44px;
  display: flex;
  align-items: center;
}

.promo-link {
  width: 160px;
  height: 55px;
  border-radius: 20px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-right: 30px;
  justify-content: center;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    text-decoration: none;
  }
}

.promo-link--google {
  .promo-link-text {
    margin-left: 10px;
  }
}

.promo-link-text {
  margin-left: 17px;
  font-weight: 600;
  font-size: 16px;
  color: #000;
}


.promo-link-web-client {
  width: 350px;
  height: 55px;
  border-radius: 20px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: center;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    text-decoration: none;
  }
}

.promo-bg {}

.promo-screen {}

.promo-divider {
  margin-top: 120px;
  margin-bottom: 56px;
  display: flex;
  justify-content: center;
}

.promo-divider-it {
  font-size: 40px;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F6F7FA;
  border-radius: 12px;
}

.promo-description {
  margin: 0 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  max-width: 970px;
  text-align: center;
  color: $g-color-black;
}